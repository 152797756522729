import { createContext } from 'react'
import { SeoGlobals, seoGlobalsDefault } from './functions/seo'
import { HeaderGlobals, headerGlobalsDefault } from './functions/header'
import { FooterGlobals, footerGlobalsDefault } from './functions/footer'

export type GlobalContextType = {
  seo: SeoGlobals
  header: HeaderGlobals
  footer: FooterGlobals
}

export const GlobalContext = createContext<GlobalContextType>({
  seo: seoGlobalsDefault,
  header: headerGlobalsDefault,
  footer: footerGlobalsDefault,
})

export type LayoutContext = {
  path: string
}