import React, { ComponentProps, useContext } from 'react'
import { Container, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { StyledNavItem } from '../PrimaryNavigation/NavItem'
import { PrimaryNavigationContext } from '../PrimaryNavigation/PrimaryNavigation'
import { rem } from '../../utils'

export type NavDropdownPanelProps = ComponentProps<typeof Grid> & {
  show?: boolean
}

export const NavDropdownPanel: React.FC<NavDropdownPanelProps> = ({ show=false, children, ...props }) => {
  const { secondary } = useContext(PrimaryNavigationContext)

  return (
    <StyledPanelWrapper $show={show} $secondary={secondary}>
      <Container maxWidth={'lg'}>
        <Grid container justifyContent={'flex-end'}>
          <Grid item xs md={11}>
            <StyledDropdownPanel container $show={show} direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={8} {...props}>
              { children }
            </StyledDropdownPanel>
          </Grid>
        </Grid>
      </Container>
    </StyledPanelWrapper>
  )
}

const StyledPanelWrapper = styled.div<{ $show?: boolean; $secondary?: boolean }>`
  visibility: ${props => props.$show ? 'visible' : 'hidden'};
  opacity: ${props => props.$show ? 1 : 0};
  will-change: opacity;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: ${props => rem(props.$secondary ? 104 : 120, props.theme)};
  left: 0;
  right: 0;
  z-index: 9;
  background: #f8f8f8;

  ${StyledNavItem}:focus-within > & {
    visibility: visible;
    opacity: 1;
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-bottom: ${props => props.theme.typography.pxToRem(45)};
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - ${props => props.theme.typography.pxToRem(45)}), 0 100%);
  }

  ${props => props.theme.breakpoints.up('lg')} {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 90px), 0 100%);
    padding-bottom: ${props => props.theme.typography.pxToRem(90)};
  }
`

export const StyledDropdownPanel = styled(Grid)<{$show?: boolean}>`
  cursor: default;
  width: 100%;
  padding: ${props => props.theme.typography.pxToRem(75)} 0 ${props => props.theme.typography.pxToRem(100)};

  > .MuiGrid-item {
    padding: 0 !important;
    width: 100%;

    > .MuiGrid-align-items-xs-flex-start {
      gap: ${props => props.theme.typography.pxToRem(25)};
    }
  }

  > .MuiGrid-container {
    padding-top: ${props => props.theme.typography.pxToRem(48)};
  }
`