import styled from 'styled-components'

export const StyledListContainer = styled.ul`
  width: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    margin-bottom: 0;

    &::before {
      display: none;
    }
  }
`