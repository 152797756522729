import React from 'react'
import { MobileSearchBar } from './MobileSearchBar'
import Grid from '@material-ui/core/Grid'
import { StyledSearchIcon, StyledSearchIconContainer } from './StyledSearchBarIcon'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

export type SearchBarProps = React.ComponentProps<typeof Grid> & {
  onChange?: (event: React.FormEvent) => void
  onToggle: () => void
  onClear: () => void
  focus: boolean
  inputRef: React.MutableRefObject<HTMLInputElement>
  sticky?: boolean
  secondary?: boolean
}

export const MobileSearchBarContainer: React.FC<SearchBarProps> = ( props ) => {
  const [openSearch, setOpen] = React.useState(false)

  const setOpenSearch = () => {
    if (openSearch) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  return(
    <React.Fragment>
      <StyledSearchIconContainer onClick={setOpenSearch}>
        <StyledSearchIcon  icon={faSearch}  />
      </StyledSearchIconContainer>
      {openSearch &&
        <MobileSearchBar {...props} />
      }
    </React.Fragment>
  )
}