import React, { ComponentProps } from 'react'
import styled, { useTheme } from 'styled-components'
import Image from 'next/image'
import logoWithoutText from './images/logo-icon.svg'
import logoText from './images/logo.svg'
import logoWithoutTextDesktop from './images/logo-icon-desktop.svg'
import logoTextDesktop from './images/logo-desktop.svg'
import { AnimatePresence, motion } from 'framer-motion'
import { useMediaQuery } from '@material-ui/core'

export type CreditUnionLogoProps = Omit<ComponentProps<typeof Image>, 'src' | 'alt' | 'blurDataURL'> & {
  notext?: boolean
}

const StyledLogoContainer = styled.figure`
  margin: 0;
  display: flex;
  position: relative;
`

const StyledTextLogo = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
`

const StyledLogoIcon = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 63px;
  transform-origin: top left;
`

export const CreditUnionLogo: React.FC<CreditUnionLogoProps> = ({ notext, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const leftOffset = isMobile ? 63 : 74

  return (
    <StyledLogoContainer>
      <AnimatePresence>
        {!notext && (
          <StyledTextLogo
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={isMobile ? logoText : logoTextDesktop} { ...props } alt={'Credit Union SA'} />
          </StyledTextLogo>
        )}
      </AnimatePresence>
      <StyledLogoIcon transition={{ stiffness: 0.1 }} animate={{
        left: notext ? 0 : leftOffset,
        transform: `scale(${notext ? (isMobile ? 0.8 : 0.95) : 1})`,
      }}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={isMobile ? logoWithoutText : logoWithoutTextDesktop} { ...props } alt={''} />
      </StyledLogoIcon>
    </StyledLogoContainer>
  )
}

export const StyledCreditUnionLogo = styled(CreditUnionLogo)`
  transition: all 0.2s ease;
`
