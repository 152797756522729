import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { Icon } from '../icon/icon'
import { SearchBarProps } from './SearchBar'

export const MobileSearchBar: React.FC<SearchBarProps> = ({ onClear, inputRef, focus }) => {
  const [inputState, setInput] = useState(false)

  useEffect(() => {
    if (inputRef != null) {
      inputRef.current.focus()
    }
  }, [focus, inputRef])

  const clickHandler = () => {
    setInput(false)

    if (onClear) {
      onClear()
    }
  }

  const onChange = (event) => {
    if (event.target.value === '') {
      setInput(false)
    }
    else {
      setInput(true)
    }
  }

  return (
    <StyledSearchContainer>
      <StyledMobileTextField
        inputRef={inputRef}
        $focus={focus}
        onChange={onChange}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'description' }}
      />
      <StyledMobileSearchIcon onClick={clickHandler} icon={inputState ? faTimes : faSearch}/>
    </StyledSearchContainer>

  )
}

const StyledSearchContainer = styled(Grid)`
  background-color: rgba(242, 242, 242, 0.8);
  padding: ${props => props.theme.typography.pxToRem(14)} ${props => props.theme.typography.pxToRem(16)} ${props => props.theme.typography.pxToRem(14)} ${props => props.theme.typography.pxToRem(16)};
  position: absolute;
  width: 100%;
  top: ${props => props.theme.typography.pxToRem(75)};
  left: 0;
`

export const StyledMobileTextField = styled(Input)<{ $focus?: boolean}>`
  background-color: ${props => props.theme.palette.common.white};
  width: 100%;
  border-radius: 4px;
  border: black 1px;
  padding: ${props => props.theme.typography.pxToRem(10)};
  font-size: ${props => props.theme.typography.pxToRem(16)};

  &::before {
    content: none;
  }

  &::after {
    content: none;
  }
`

const StyledMobileSearchIcon = styled(Icon)`
  position: absolute;
  right: 38px;
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  top: 50%;
  transform: translate(0, -50%);
`