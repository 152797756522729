import React from 'react'
import styled from 'styled-components'
import { Link, LinkProps } from '../link/link'
import { Typography } from '@material-ui/core'

export type NavDropdownLinkItemProps = React.ComponentProps<typeof StyledLinksItem> & Pick<LinkProps, 'href'> & {
  //
}

export const StyledLinksItem = styled.div`
  line-height: ${props => props.theme.typography.pxToRem(38)};
  cursor: pointer;

  > a {
    text-decoration: none;
  }
`

export const StyledLinksItemText = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(17)};
  margin-bottom: 0;
  transition: color 0.2s ease;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(19)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }
`

export const NavDropdownLinkItem: React.FC<NavDropdownLinkItemProps> = ({ href, children, ...props }) => {
  return (
    <StyledLinksItem {...props}>
      <Link href={href} passHref>
        <StyledLinksItemText>{ children }</StyledLinksItemText>
      </Link>
    </StyledLinksItem>
  )
}
