import React from 'react'
import { Grid } from '@material-ui/core'
import { NavDropdownLinkCard, NavDropdownLinkCardProps } from './NavDropdownLinkCard'
import { NavDropdownLinkItem, NavDropdownLinkItemProps } from './NavDropdownLinkItem'

export type NavDropdownLinksProps =  (
  | ({ card?: false; item?: false } & Omit<React.ComponentProps<typeof Grid>, 'direction'|'justifyContent'|'alignItems'>)
  | ({ card: true; item?: false } & NavDropdownLinkCardProps)
  | ({ card?: false; item?: true } & NavDropdownLinkItemProps)
)

export const NavDropdownLinks: React.FC<NavDropdownLinksProps> = ({ card, item, ...props }) => {
  if (card) {
    return <NavDropdownLinkCard { ...props as NavDropdownLinkCardProps} />
  }

  if (item) {
    return <NavDropdownLinkItem { ...props as NavDropdownLinkItemProps} />
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" {...props} />
  )
}
