import { LayoutQueryFunction } from '../layout'
import { client } from '../../apollo/client'
import { gql } from '@apollo/client'

export type SeoGlobals = {
  title: string
  meta: string
  link: string
  jsonLd: string
}

export const seoGlobalsDefault = {
  title: '',
  meta: '',
  link: '',
  jsonLd: '',
}

export const SEO_QUERY = gql`
  query SEOQuery($uri: String!) {
    seo: seomatic(uri: $uri) {
      metaTitleContainer
      metaTagContainer
      metaLinkContainer
      metaJsonLdContainer
    }
  }
`

export const getSeoGlobal: LayoutQueryFunction<SeoGlobals> = async (context) => {
  const { data } = await client.query<{
    seo: {
      metaTitleContainer: string
      metaTagContainer: string
      metaLinkContainer: string
      metaJsonLdContainer: string
    }
  }>({
    query: SEO_QUERY,
    variables: {
      uri: context.path,
    },
  })

  return {
    title: data.seo.metaTitleContainer,
    meta: data.seo.metaTagContainer,
    link: data.seo.metaLinkContainer,
    jsonLd: data.seo.metaJsonLdContainer,
  }
}