import React, { useContext } from 'react'
import styled from 'styled-components'
import { StyledListItem, StyledListItemGrid, StyledMenuItemText } from './styledMobileMenuItem'
import { Grid } from '@material-ui/core'
import { Icon } from '../../icon/icon'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { MobileMenuContext } from '../MobileMenuContext'

export type MobileMenuNavigationItemProps = React.ComponentProps<typeof StyledListItem> & {
  level?: number
  idx?: number
  name?: string
  hasChildren?: boolean
}

export const StyledCheveronRight = styled(Icon)`
  position: relative;
  top: 3px;
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-right: ${props => props.theme.typography.pxToRem(8)};
`

export const MobileMenuNavigationItem: React.FC<MobileMenuNavigationItemProps> = ({ name, level, hasChildren, idx, children, ...props }) => {
  const menuContext = useContext(MobileMenuContext)
  const onClick = (event) => {
    event.stopPropagation()
    menuContext.setMenuOpen(level ?? 0, (idx ?? 0) + 1)
  }

  return (
    <StyledListItem $secondary={Boolean(level && level > 0)} onClick={onClick} { ...props }>
      <StyledListItemGrid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
            <Grid item>
              <StyledMenuItemText>{ name }</StyledMenuItemText>
            </Grid>
          </Grid>
        </Grid>
        {hasChildren && (
          <Grid item>
            <StyledCheveronRight icon={faChevronRight} />
          </Grid>
        )}
        { children }
      </StyledListItemGrid>
    </StyledListItem>
  )
}
