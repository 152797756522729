import styled from 'styled-components'
import { Icon } from '../icon/icon'
import { Button } from '../button/button'
import { Typography } from '@material-ui/core'

export const StyledNavIcon = styled(Icon)<{$isProfile?: boolean}>`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  margin-right: ${props => props.$isProfile ? 0 : props.theme.typography.pxToRem(24)};
  color: ${props => props.$isProfile && props.theme.palette.secondary.dark};
  transition: color 0.2s ease;

  &:hover {
    cursor: pointer;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: ${props => props.theme.typography.pxToRem(12)};
    padding: 0;
  }
`

export const StyledBrokenBarIcon = styled.button`
  background: none;
  border: none;
  padding: 0;
`

export const StyledIconNavItem = styled.div<{ $focus?: boolean }>`
  display: grid;
  justify-items: center;
  list-style-type: none;

  ${props => props.theme.breakpoints.up('sm')} {
    display: inline-block;
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }
`

export const StyledLoginButton = styled(Button)`
  font-size: ${props => props.theme.typography.pxToRem(12)};
  padding: 7px 9px 5px;
  border-color: ${props => props.theme.palette.primary.main};
  transition: background 0.2s ease, color 0.2s ease;

  .MuiButton-startIcon svg {
    position: relative;
    top: -1px;
    font-size: 1.2em;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(18)};
    padding: 15px 25px 14px 25px;

    .MuiButton-startIcon svg {
      position: relative;
      top: -1px;
      font-size: 1em;
    }
  }

  &::after {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: white;
    background: ${props => props.theme.palette.primary.main};
    border-color: ${props => props.theme.palette.primary.main};

    .MuiButton-label {
      transform: none;
    }
  }
`

export const StyledNavBSB = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(18)};
  font-weight: 500;

  & > span {
    font-weight: 300;
    padding-right: ${props => props.theme.typography.pxToRem(5)};
    color: ${props => props.theme.palette.secondary.dark};
  }
`